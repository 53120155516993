import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import styles from "../../../SCSS/CMSPages.module.scss";
import HelmetComponent from "../../../SupportingFiles/HelmetComponent";
import useIsMobile from "../../../SupportingFiles/MobileProvider";

function TermsandConditions() {
  const isMobile = useIsMobile();
  return (
    <Box className={styles.cmsWrapper}>
      <HelmetComponent
        title="Jio Insurance Broking Ltd"
        description="Get your car, bike, health, or Life insurance online. Comprehensive coverage across India. Book now and get the right quotes! JioInsure - Jio General Insurance"
      />
      {isMobile ? <MNavBar title={"TERMS OF USE"} /> : <NavBar />}

      <Grid container spacing={3} className={styles.cmsPages}>
        <Grid size={12}>
          <h2>
            TERMS AND CONDITIONS FOR USAGE OF JIO INSURANCE BROKING LIMITED
          </h2>
          <h4>1. INTRODUCTION</h4>
          <p>
            These terms and conditions ("Terms") are entered into between you
            (“you” or “user”) and Jio Insurance Broking Limited (formerly known
            as Reliance Retail Insurance Broking Limited) (“we” or “us” or “our”
            or “JIBL”) for accessing, registration and interacting with the
            Insurance Self-Network (“ISNP”) (i.e., website/mobile application)
            with the URL: www.jioinsure.in or the mobile application
            "JioFinance" (hereinafter referred to as the "Platform") on any
            device. Additionally, carefully consider these Terms before availing
            any insurance intermediation services offered by us on the Platform,
            referred to individually and collectively as the JIBL Services.
          </p>
          <p>
            By using the Platform, you acknowledge and agree that JIBL acts as
            an insurance broker facilitating our ability to solicit insurance
            policies. Each Insurer has its own terms and conditions for the
            Policy (defined below). These terms and conditions are available
            within the Policy, annexed to the policy document and related
            documents at the time of Policy issuance by the Insurer.
          </p>
          <p>
            When purchasing a Policy through JIBL, you further confirm that you
            have read and comprehended the Policy terms and conditions provided
            by the Insurer. It is essential to acknowledge that the terms and
            conditions of a Policy constitute contractual arrangements
            exclusively between you and the Insurer.
          </p>
          <p>
            This document / contract / understanding is an electronic record
            published in accordance with the provisions of the Information
            Technology Act, 2000 and rules made thereunder as applicable and the
            amended provisions pertaining to electronic records in various
            statutes as may be amended by the Information Technology Act, 2000
            from time to time. This electronic record is generated by a computer
            system and does not require any physical or digital signatures.
          </p>
          <p>
            By continuing to use or access the Platform, you agree to be bound
            by these Terms. If you do not agree to these Terms, you are advised
            to not use or access the JIBL Services on the Platform.
          </p>
          <p>
            Your access to the Platform signifies your acceptance of these
            Terms. We reserve the right to modify these Terms at any time
            without notice, and your continued access of the Terms after any
            modifications will constitute your acceptance of such modifications.
          </p>
          <h4>2. DEFINITIONS</h4>
          <p>
            Capitalised terms not defined in the Terms shall mean as follows:
          </p>
          <ul>
            <li>
              <b>"Affiliate(s)"</b> means with respect to JIBL, any person
              directly or indirectly controlling, controlled by, or under direct
              or indirect common control with JIBL including its holding
              company, fellow subsidiaries, and associate companies.
            </li>
            <li>
              <b>“Agreement”</b> means the Terms and Privacy Policy of JIBL as
              amended, novated, supplemented, varied, or replaced from time to
              time.
            </li>
            <li>
              <b>“Applicable Laws”</b> mean Insurance Act, 1938, Insurance
              Regulatory and Development Authority Act 1999, IRDAI (Issuance of
              e-insurance policies) Regulations 2016, IRDAI (Insurance Brokers)
              Regulations 2018, IRDAI Guidelines on Insurance e-commerce dated 9
              March 2017, IRDAI (Web Aggregator) Regulations 2017, and the IRDAI
              Information and Cybersecurity Guidelines dated 24 April 2023 and
              other relevant rules and regulations applicable to insurance
              brokers.
            </li>
            <li>
              <b> "Content"</b> means all text, graphics, user interfaces,
              visual interfaces, photographs, trademarks, logos, sounds, music,
              artwork, and computer code collectively.
            </li>
            <li>
              <b>"Code of Conduct"</b> means code of conduct as prescribed under
              the IRDA (Insurance Brokers) Regulations, 2018 (as amended from
              time to time).
            </li>
            <li>
              <b>"Force Majeure Event"</b> means any event due to any cause
              beyond the reasonable control of JIBL, including, without
              limitation, unavailability of any communication system, breach or
              virus in the systems, fire, pandemic, flood, explosion, acts of
              God, civil commotion, riots, insurrection, war, and acts of
              government.
            </li>
            <li>
              <b>“Form”</b> means the insurance application form and its
              contents, mandated by an Insurer, which the User is required to
              submit for the underwriting and issuance of a Policy and includes
              a proposal form.
            </li>
            <li>
              <b>"Insurance Act"</b> means the Insurance Act, 1938 (as amended
              from time to time).
            </li>
            <li>
              <b> "IRDAI"</b> means Insurance Regulatory and Development
              Authority of India.
            </li>
            <li>
              <b>“Insurer”</b> means the entity who has issued the Policy and
              has tied up with JIBL for Solicitation of its Policies through
              JIBL.
            </li>
            <li>
              <b>“JIBL” or “we” or “us” or “our''</b> means Jio Insurance
              Services Limited, a Direct Insurance Broker (Life & General) duly
              registered with the IRDAI (Registration No. 347. Valid till 11
              March 2025) whose registered office is at  1<sup>st</sup> Floor, Building - 4NA, Maker Maxity, Bandra Kurla Complex, Bandra East, Mumbai, Maharashtra - 400 051
            </li>
            <li>
              <b>"JIBL Service(s)"</b> means any insurance intermediation and
              any other services ancillary to insurance intermediation business
              including display of information of the User (defined below) such
              as information related to loan accounts, deposits, bank accounts,
              demat accounts, e-insurance accounts, etc. for the purpose of
              giving the User a holistic view of her financial position with
              analytical insights and data offered by us on the Platform,
              subject to compliance with the Insurance Regulatory Development
              Authority (Insurance Broker) Regulations 2018 (as amended from
              time to time) and such other services permitted under Applicable
              Laws.
            </li>
            <li>
              <b>“JioFinance”</b> means the website, mobile application, page,
              interface, and any other technological platform owned and operated
              by Jio Leasing Services Limited.
            </li>
            <li>
              <b>“Person”</b> means any natural person, company, corporation,
              partnership, proprietorship, trust, union, association, government
              or any agency thereof or any other entity that may be treated as a
              person under Applicable Laws.
            </li>
            <li>
              <b>"Platform"</b> means the website, mobile application, page,
              interface, and any other technology platform owned and operated by
              JIBL.
            </li>
            <li>
              <b>“Policy”</b> means various insurance products and services of
              the Insurers.
            </li>
            <li>
              <b>“Policyholder”</b> means a person who purchases a Policy
              through JIBL.
            </li>
            <li>
              <b>“Privacy Policy"</b> means the privacy policy of JIBL as
              provided on the Platform.
            </li>
            <li>
              <b>"Solicitation"</b> refers to the procedure in which the
              proposer comprehends the benefits and requirements of the Policy
              and expresses their interest in acquiring the Policy.
            </li>
            <li>
              <b> “User” or “you”</b> means any natural or legal person who
              accesses or uses the Platform for obtaining information about
              various Policies and in the event that a natural person is
              representing a business entity or a body corporate, reference to
              such terms shall include a reference to such business entity and /
              or body corporate as well and other promoters of such business
              entity and / or body corporate. All references to “you” shall
              include yourself and any other persons you are authorised to and
              required to provide consent for.
            </li>
          </ul>
          <h4>3. USE OF PLATFORM</h4>
          <ul>
            <li>
              You agree and acknowledge that JIBL is only assisting in
              collecting the premium on behalf of the Insurer chosen by you to
              avail the Policy. The acceptance of the deposit as premium and
              final issuance of the Policy is subject to the underwriting norms
              and discretion of the Insurer on which JIBL has no control, and
              that product information is solely based on the information
              received from the Insurers.
            </li>
            <li>
              The Policies available on the Platform are developed, owned,
              operated and serviced by the respective Insurers and are being
              offered or made available to you through the Platform. For more
              details on risk factors, terms and conditions, please read the
              sales brochure/customer information sheet/policy wordings of a
              particular plan carefully before concluding a sale.
            </li>
            <li>
              You understand that JIBL cannot and does not guarantee or warrant
              that the files available for download through the Platform will be
              free of viruses, worms or other code that may be damaging. You are
              responsible for implementing procedures to satisfy your particular
              requirements of internet security and for accuracy of data input
              and output.
            </li>
            <li>
              JIBL does not control or endorse the content, messages or
              information found in any Policies provided by the Insurers and
              merely acts as an insurance broker. Therefore, we specifically
              disclaim any liability with regard to the Policies and services
              offered by Insurers and any actions resulting from your
              participation in such products and services, and you agree that
              you waive any claims against JIBL relating to same, and to the
              extent such waiver may be ineffective, you agree to release any
              claims against JIBL relating to the same.
            </li>
            <li>
              We encourage all Users to read the Policy document carefully and
              avail the free look facility in case the terms and conditions of
              the Policy document does not match your insurance requirements.
            </li>
            <li>
              Further, you understand that once you place a cancellation request
              within the free look period, the Policy gets cancelled and entire
              premium is refunded to you subject to deduction of (i) charges
              pertaining to medical tests conducted; (ii) administrative and
              service cost like stamp duty etc; and (iii) charges for mortality
              for the period the Policy was in force. Please note such deduction
              is at the sole discretion of the Insurer.
            </li>
            <li>
              In case of a refund of premium due to an erroneous transaction or
              cancellation of the Policy, the premium paid for the Policy will
              be refunded or partly refunded (as may be applicable) via cheque
              or direct credit as per the policy of the Insurer that you have
              chosen to buy the Policy from.
            </li>
            <li>
              Although JIBL endeavours to make correct Policy comparisons,
              quotes, features, etc., based on the information provided by the
              Insurers, JIBL, its directors, shareholders, officers, employees
              and point of sale persons are in no way responsible to or liable
              to any User for their decision to purchase a Policy or any
              investment decision, and every User / Policyholder shall be solely
              responsible for the consequences of their decision.
            </li>
            <li>
              Tax benefits under the Policy will be as per the prevailing income
              tax laws and are subject to amendments from time to time. IRDAI is
              not involved in activities like selling Policies, announcing bonus
              or investment of premium. If you receive any such phone calls,
              then, please lodge a complaint with police.
            </li>
          </ul>
          <h4>4. REPRESENTATIONS AND WARRANTIES OF JIBL</h4>
          <p>
            By providing you access to the Platform, JIBL hereby represents
            that:
          </p>
          <ul>
            <li>
              (a) We maintain clear and adequate records of each Policyholder's
              claims.
            </li>
            <li>
              (b) We refrain from exerting influence on prospective
              Policyholders, quoting terms precisely as provided by the Insurer.
              Confirmation of the affected insurance will be furnished to the
              User / Policyholder. In case of any changes to the terms and
              conditions of an insurance contract notified to us by the Insurer,
              we will notify the Policyholder, providing reasonable notice,
              wherever possible, before changes take effect.
            </li>
            <li>
              (c) We inform Policyholders of any proposed insurance with an
              overseas Insurer, where permitted, and communicate potential risks
              involved. Letters of instruction, policies, and renewal documents
              will contain details of complaints handling procedures.{" "}
            </li>
            <li>
              (d) We commit to compliance with the provisions of Applicable
              Laws.
            </li>
            <li>
              (e) We are in compliance with section 41 of the Insurance Act,
              which states that “(1) No person shall allow or offer to allow,
              either directly or indirectly, as an inducement to any person to
              take out or renew or continue an insurance in respect of any kind
              of risk relating to lives or property in India, any rebate of the
              whole or part of the commission payable or any rebate of the
              premium shown on the policy, nor shall any person taking out or
              renewing or continuing a policy accept any rebate, except such
              rebate as may be allowed in accordance with the published
              prospectuses or tables of the insurer. Provided that acceptance by
              an insurance agent of commission in connection with a policy of
              life insurance taken out by himself on his own life shall not be
              deemed to be acceptance of a rebate of premium within the meaning
              of this subsection if at the time of such acceptance the insurance
              agent satisfies the prescribed conditions establishing that he is
              a bona fide insurance agent employed by the insurer. (2) Any
              person making default in complying with the provisions of this
              section shall be liable for a penalty which may extend to ten lakh
              rupees.”
            </li>
          </ul>
          <h4>5. REPRESENTATION AND WARRANTIES OF THE USER</h4>
          <p>
            By accessing, logging in, browsing, or using the Platform, you
            hereby represent and warrant that:
          </p>
          <ul>
            <li>You are a resident of India.</li>
            <li>You are over 18 years of age.</li>
            <li>You do not have more than one active account with us.</li>
            <li>
              You are prohibited from selling, trading, or otherwise
              transferring your account to another party.
            </li>
            <li>
              You are duly authorized, either individually or as an authorized
              signatory of an entity.
            </li>
            <li>
              You have the capacity to enter into a legally binding agreement.
            </li>
            <li>
              You are not barred or legally prohibited from accessing or using
              the Platform.
            </li>
            <li>
              You acknowledge and agree that we own all legal rights, titles and
              interests in and to the JIBL Services, including any intellectual
              property rights which subsist in the JIBL Services.
            </li>
            <li>
              You acknowledge and understand that JIBL is considered as a
              'financial information user' under Non-Banking Financial Company -
              Account Aggregator (Reserve Bank) Directions, 2016.
            </li>
            <li>
              You do not have the right to use any of our trade names,
              trademarks, service marks, logos and domain names. You do not have
              the right to remove, obscure, or alter any proprietary rights
              notices (including trademark and copyright notices), which may be
              affixed to or contained within the JIBL Services. You will not
              copy or transmit any of the JIBL Services.
            </li>
            <li>
              The information requested from you is used for the various
              purposes as set out in our Privacy Policy as well as compliance
              with various laws, regulations and guidelines promulgated by the
              Government of India from time to time.{" "}
            </li>
            <li>
              If at any time you do not accept or agree with any of the Terms or
              do not wish to be bound by the Terms, you may not access, browse
              or use the Platform and immediately terminate availing the JIBL
              Services.
            </li>
            <li>
              You will remain responsible for all actions taken through your
              system, username, and password.
            </li>
            <li>
              You shall keep us updated of any change in the information
              provided on the Platform.
            </li>
            <li>
              We provide all insurance intermediation services through the
              Platform under the brand name "Jio Insurance Broking Limited" (or
              any derivatives or variations thereof). Consequently, all rights,
              benefits, liabilities, and obligations under the Terms shall, as
              the case may be, accrue to the benefit of, or be incurred by,
              JIBL, in relation to your use of JIBL Services, from time to time.
            </li>
            <li>
              You are interested in availing the Policy for which you have
              submitted the required information or details.
            </li>
            <li>
              You use the Platform solely for legitimate purchases for yourself
              or for another person for whom you are legally authorized to act.
              In the latter case, you agree to inform such individuals about the
              applicable Terms and Privacy Policy, including all pertinent rules
              and restrictions relating to their purchases.
            </li>
            <li>
              You will provide true, accurate, current, and complete information
              about yourself as prompted by the Platform or the Form. JIBL, in
              its capacity as an insurance broker, disclaims any liability for
              losses incurred by you or your heirs due to incorrect information
              or disclosure.
            </li>
            <li>
              If any information provided by you becomes untrue, inaccurate,
              misleading, not updated, or incomplete, or if JIBL or the Insurer
              suspects the authenticity of such information, JIBL and the
              Insurer reserve the right to refuse, suspend, or terminate your
              account and deny any or all current or future use of the Platform.
            </li>
            <li>
              The responsibility for maintaining the confidentiality of your
              account and password, and restricting access to your mobile phone,
              lies with you when using this Platform. You agree to notify JIBL
              immediately of any unauthorized use of your account or any other
              breach of security.
            </li>
            <li>
              JIBL retains the right to refuse service, terminate accounts, or
              edit content at its sole discretion at any time and for any
              reason, including violation of the Terms or Privacy Policy.
            </li>
            <li>
              You are obligated to adhere to the terms and conditions laid down
              by the bank / payment gateway/ payment aggregator / payment
              facilitator / card network processing your insurance premium
              payment.
            </li>
            <li>
              You are obligated to reasonably cooperate with JIBL for compliance
              of directions issued by the IRDAI or any other statutory authority
              in relation to: (a) KYC / AML matters and agree to provide all
              such documents specified in the IRDAI Master Guidelines on
              Anti-Money Laundering/ Counter Financing of Terrorism (AML/CFT),
              2022 dated 01st August 2022 as far as applicable and (b) any other
              inspection, investigation, audits of the IRDAI or any other
              statutory authority.{" "}
            </li>
          </ul>
          <h4>6. KNOW YOUR CUSTOMER (“KYC”)</h4>
          <ul>
            <li>
              For any financial transactions facilitated through the Platform,
              JIBL or its Affiliates, as permitted, will undertake customer due
              diligence measures. You agree to provide any information necessary
              for KYC purposes, as mandated while requesting insurance
              requirements with the respective Insurer.
            </li>
            <li>
              These measures are conducted in compliance with applicable
              Prevention of Money Laundering Act ("PMLA") laws and rules,
              adhering to the directives of the Insurer or IRDAI.
            </li>
            <li>
              JIBL or the Insurer may request additional information to
              establish, to their satisfaction, the identity of each new User.
              This process aims to clarify the purpose of the intended nature of
              the insurance relationship between you and the Insurers.
            </li>
            <li>
              You acknowledge that all insurance premium payments should be
              remitted only through your bank account or from a joint bank
              account where you are a joint holder. If payment is made through a
              third-party bank account not in your name, enhanced due diligence
              measures may be undertaken by JIBL or the Insurer. This includes
              the possibility of additional documentation to satisfy customer
              due diligence requirements.
            </li>
          </ul>
          <h4>7. PROPRIETARY RIGHTS</h4>
          <ul>
            <li>
              JIBL provides you with a limited license to access and use the
              Platform on JioFinance and the JIBL Services. This license
              explicitly excludes downloading or copying information for the
              benefit of another individual, vendor, or any third party.
              Unauthorized activities such as accessing the Platform without
              proper authorization, scraping content without rights, uploading
              malicious software, or taking actions that impose an unreasonable
              load on JIBL's infrastructure are strictly prohibited. Any
              unauthorized use will result in the termination of the granted
              permission or license.
            </li>
            <li>
              By using the JIBL Services, you agree not to engage in commercial
              use, speculative or fraudulent transactions, or unauthorized
              access through automated or manual means. You are prohibited from
              framing or incorporating any part of the Platform into another
              without written authorization and violating the Terms and Privacy
              Policy concerning access or usage of the computer resource.
            </li>
            <li>
              Furthermore, you are expressly prohibited from hosting,
              displaying, uploading, modifying, publishing, transmitting,
              storing, updating, or sharing any information on the Platform that
              infringes on the rights of others, is harmful to children/minor,
              violates laws, deceives or misleads, impersonates, threatens
              national integrity, or contains harmful software. Such activities
              will result in stringent legal actions, in accordance with the
              Applicable Laws.
            </li>
            <li>
              The software and hardware that support the Platform, as well as
              other internet-related software necessary for accessing the
              Platform, are solely our property and/ or our authorised
              third-party service providers.
            </li>
          </ul>
          <h4>8. SOLICITATION TERMS</h4>
          <ul>
            <li>
              JIBL provides access to information primarily focused on Policies
              and services, including but not limited to renewals. Access to the
              service is contingent on internet/mobile data and other potential
              third-party fees. Additionally, Users must provide and are
              responsible for all equipment necessary to access the Platform.
            </li>
            <li>
              JIBL provides, in electronic form, a summary of information
              provided by you in the Form before you purchase a Policy.
            </li>
            <li>
              JIBL provides the Policyholder with a copy of the Policy in
              electronic format for such Policyholder’s record keeping purposes.
            </li>
            <li>
              JIBL provides the Policyholder, through electronic means, post
              sales servicing of any Policy facilitated through the Platform.
            </li>
            <li>
              You acknowledge that JIBL reserves the right to share your details
              with its Affiliates and Insurers, who may contact you for
              information and sales via email, telephone, and/or SMS. Consent is
              also given to receive promotional materials from JIBL through
              various communication tools.
            </li>
            <li>
              The insurance contract is based on the principle of 'uberrima
              fides' or the Doctrine of Utmost Good Faith. It is your duty to
              disclose all information related to or material to the Policy
              solicited through JIBL. Non-disclosure may lead to the
              cancellation of proposals, insurance covers, or the
              repudiation/rejection of claims. JIBL shall not be liable for any
              losses arising due to such non-disclosure.
            </li>
            <li>
              Policies available on the Platform are offered and underwritten by
              respective Insurers. JIBL, as a direct insurance broker, acts as a
              representative of the User / Policyholder. JIBL is not an agent,
              corporate agent, or other intermediary representing the Insurers.
              JIBL collects premiums on behalf of the chosen Insurer, and the
              final issuance of the Policy is subject to the underwriting norms
              and discretion of the Insurer.
            </li>
            <li>
              The Insurer underwriting the particular Policy purchased /
              serviced through the Platform is solely responsible for settling
              any claim and taking any decisions on claim settlements. [For
              information on buying insurance, standard claim
              procedures/documentation, dos and don'ts, general alerts, dealing
              with intermediaries, and FAQs - visit IRDAI's customer education
              website http://www.policyholder.gov.in/]
            </li>
            <li>
              Tax benefits under the Policy will be as per the prevailing income
              tax laws and are subject to amendments from time to time. For
              tax-related queries, please contact your independent tax advisor.
            </li>
            <li>
              Users are advised to read the sales brochure, customer information
              sheet, and Policy wordings of a particular plan carefully before
              purchase of a Policy.
            </li>
            <li>
              Insurance is a subject matter of Solicitation, and the Policy is
              issued based on a contract between the insured and the Insurer.
            </li>
            <li>
              JIBL recommends notifying changes in your communication address
              and the appointment of a nominee during Solicitation or after
              purchasing a Policy.
            </li>
            <li>
              In the event of a claim against a Policy, the claim process
              defined by your Insurer will be followed, and JIBL's role will be
              limited to assisting you in sharing the process for such a claim
              if requested.
            </li>
          </ul>
          <h4>9. REGISTRATION</h4>
          <p>
            While registering on our Platform, you commit to furnishing accurate
            and comprehensive information. It is your responsibility to maintain
            the accuracy and completeness of this information for the purpose of
            using the JIBL Services and for linking any of your financial
            accounts through account aggregators. We reserve the right to
            suspend or terminate your account and deny access to the Platform
            (or any part of it) if there is suspicion or evidence of false or
            incomplete information provided by you.
          </p>
          <h4>10. OBLIGATIONS OF THE USER</h4>
          <ul>
            <li>
              <b>Review of Terms.</b> It is your responsibility to thoroughly
              review these Terms on a periodic basis.
            </li>
            <li>
              <b>Use of Content.</b> The Content contained on this Platform is
              provided for general information only and should not be used as a
              basis for making business / commercial decisions. You are advised
              to exercise due caution and / or seek independent financial advice
              before availing any facility or entering into any financial
              obligation based on the Content contained on this Platform.
            </li>
          </ul>
          <h4>11. TERMINATION</h4>
          <p>
            We reserve the right to permanently or temporarily terminate,
            suspend, or deny access to the Platform without notice or liability
            for violations of the Terms or the provision of Platform to you, is
            no longer commercially viable or feasible for us.{" "}
          </p>
          <h4>12. ACCESS</h4>
          <p>
            We reserve the right to withdraw or amend the Platform without
            notice. We shall not be responsible for interruptions or cessation
            of the Platform and the JIBL Services. We may restrict access to
            some parts of the Platform to Users from time to time for
            maintenance and other purposes. We make no warranties regarding the
            access, speed, or availability of the internet in general or the
            Platform in particular. The Platform is controlled from India, and
            users from other jurisdictions must comply with Indian laws.
          </p>
          <p>Your responsibilities for accessing the Platform include:</p>
          <ul>
            <li>Ensuring access to the Platform is appropriately arranged.</li>
            <li>
              Ensuring that all individuals accessing the Platform through your
              internet connection or device are informed about and compliant
              with the Terms.
            </li>
            <li>
              Providing accurate, current, and complete information when
              prompted for registration or other details to access the
              Platform's resources. Your usage of the Platform is subject to the
              accuracy and completeness of the information you provide. This
              information is also governed by our Privacy Policy, and you
              consent to our actions regarding your information in line with the
              Privacy Policy.
            </li>
            <li>
              Acknowledging our right to disable any username, password, or
              identifier, whether chosen by you or provided by us, at our sole
              discretion, for any reason, including if, in our judgment, you
              have violated any provision of the Terms and/or Applicable Laws.
            </li>
          </ul>
          <h4>13. OWNERSHIP OF INTELLECTUAL PROPERTY RIGHTS</h4>
          <ul>
            <li>
              We and our authorised partners retain exclusive rights and
              ownership on the Platform content (except user content and third
              party owned content), and the features and functionality of the
              Platform. You may not use, copy, or reproduce these materials
              without prior written consent. All intellectual property rights
              are protected under applicable laws.
            </li>
            <li>
              The Content on the Platform including but not limited to text,
              photographs, graphics, video and audio Content, financial data,
              news, research, recommendations, and opinions, is protected by
              copyright in favour of us under applicable copyright laws and is
              also safeguarded under general intellectual property law.
            </li>
            <li>
              Third-party content appearing on the Platform is the property of
              their respective owners and we do not assert any rights in
              relation to the same. Such third-party content is used by us
              either on the basis permission from the third-party or pursuant
              fair use provisions.
            </li>
          </ul>
          <h4>14. INDEMNITY</h4>
          <p>
            We shall not be liable in case you breach any terms or conditions
            provided under the Privacy Policy or these Terms and will not be
            obligated to indemnify you for any direct or indirect losses
            accruing to you. You agree to indemnify, defend and hold harmless
            us, our affiliates, group companies and our directors, officers,
            employees, agents, third party service providers, and any other
            third party providing any service to us in relation to the JIBL
            Services whether directly or indirectly, from and against any and
            all losses, liabilities, claims, damages, costs and expenses
            (including legal fees and disbursements in connection therewith and
            interest chargeable thereon) asserted against or incurred by us that
            arise out of, result from, or may be payable by virtue of, any
            breach or non-performance of any terms of the Terms including any
            representation, warranty, covenant or agreement made or obligation
            to be performed by You pursuant to the Terms.
          </p>
          <h4>15. LIMITATION OF LIABILITY</h4>
          <p>
            IN NO EVENT SHALL JIO INSURANCE BROKING LIMITED, ITS OFFICERS,
            DIRECTORS, AND EMPLOYEES, OR ITS CONTRACTORS, AGENTS, LICENSORS, OR
            SUPPLIERS, AFFILIATES, AND GROUP COMPANIES BE LIABLE TO YOU FOR ANY
            DIRECT, SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE,
            RELIANCE, OR EXEMPLARY DAMAGES. THIS INCLUDES, WITHOUT LIMITATION,
            LOST BUSINESS OPPORTUNITIES, LOST REVENUES, OR LOSS OF ANTICIPATED
            PROFITS, OR ANY OTHER PECUNIARY OR NON-PECUNIARY LOSS OR DAMAGE OF
            ANY NATURE WHATSOEVER. THIS ENCOMPASSES ABUSE OR BREACH OF DATA,
            EVEN IF JIO INSURANCE BROKING LIMITED OR ITS PARTNERS HAVE BEEN
            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </p>
          <p>THESE DAMAGES MAY ARISE OUT OF OR RELATE TO:</p>
          <p>(a) THESE TERMS,</p>
          <p>(b) THE PLATFORM,</p>
          <p>(c) YOUR USE OR INABILITY TO USE THE PLATFORM, OR</p>
          <p>
            (d) ANY OTHER INTERACTIONS WITH ANOTHER USER IN CONNECTION WITH THE
            PLATFORM.
          </p>
          <p>
            WE SPECIFICALLY AND EXPRESSLY DISCLAIM THE ACCURACY AND CORRECTNESS
            OF THE CONTENT AVAILABLE ON THE PLATFORM.
          </p>
          <p>
            YOU ACKNOWLEDGE AND AGREE THAT JIBL OFFERS THE POLICIES OF INSURERS
            AND ITS SERVICES AND HAS ENTERED INTO THESE TERMS IN RELIANCE UPON
            THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH
            HEREIN. THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY
            SET FORTH HEREIN REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK
            BETWEEN YOU AND JIBL. THESE PROVISIONS FORM AN ESSENTIAL BASIS OF
            THE BARGAIN BETWEEN YOU AND JIBL. APPLICABLE LAW MAY NOT COMPLETELY
            ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY OR INCIDENTAL OR
            CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS OR EXCLUSIONS WILL
            APPLY TO YOU SUBJECT TO APPLICABLE LAW. IN SUCH CASES, JIBL'S
            LIABILITY WILL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY
            APPLICABLE LAW. THIS PARAGRAPH SHALL SURVIVE THE TERMINATION OF
            THESE TERMS.
          </p>
          <h4>16. PROHIBITED CONDUCT</h4>
          <p>
            You will not host, display, upload, modify, publish, transmit,
            update or share any information that:
          </p>
          <ul>
            <li>
              belongs to another person and to which the user does not have any
              right;
            </li>
            <li>
              is defamatory, obscene, pornographic, pedophilic, invasive of
              another privacy including bodily privacy, insulting or harassing
              on the basis of gender, libelous, racially or ethnically
              objectionable, relating or encouraging money laundering or
              gambling, or otherwise inconsistent with or contrary to the laws
              in force;
            </li>
            <li>is harmful to child;</li>
            <li>
              infringes any patent, trademark, copyright or other proprietary
              rights;
            </li>
            <li>violates any law for the time being in force;</li>
            <li>
              deceives or misleads the addressee about the origin of the message
              or knowingly and intentionally communicates any information which
              is patently false or misleading in nature but may reasonably be
              perceived as a fact;
            </li>
            <li>impersonates another person;</li>
            <li>
              threatens the unity, integrity, defence, security or sovereignty
              of India, friendly relations with foreign States, decency or
              morality in relation to contempt of court, defamation or public
              order, or causes incitement to the commission of any cognizable
              offence or prevents investigation of any offence or is insulting
              other nation as per clause (b) of sub-section (3) of section 79 of
              Information Technology Act 2000 as amended from time to time;
            </li>
            <li>
              contains software virus or any other computer code, file or
              program designed to interrupt to destroy or limit the
              functionality of any computer resource; and
            </li>
            <li>
              is patently false and untrue, and is written or published in any
              form, with the intent to mislead or harass a person, entity or
              agency for financial gain or to cause any injury to any person.
            </li>
          </ul>
          <p>
            You shall be solely liable for any damages resulting from any
            violation of the foregoing restrictions, or any other harm resulting
            from your posting of content to this Platform.
          </p>
          <h4>17. ASSIGNMENT/NOVATION</h4>
          <p>
            You understand and acknowledge that JIBL may assign / novate its
            rights and responsibilities under this Terms to any third party
            (including pursuant to any corporate restructuring or similar
            exercises). You confirm that you have no objection to such
            assignment / novation by JIBL, and you agree that your obligations
            under this Terms would be binding on such third party. We assure you
            that irrespective of any change in the contracting party, your
            rights and our obligations under this Terms will remain
            unaffected/unchanged.
          </p>
          <h4>18. FORCE MAJEURE</h4>
          <p>
            We shall not be liable for any failure to perform any of its
            obligations under the Terms or provide the JIBL Services or any part
            thereof if the performance is prevented, hindered or delayed by a
            Force Majeure Event and in such case its obligations shall be
            suspended for so long as the Force Majeure Event continues.
          </p>
          <h4>19. GOVERNING LAW</h4>
          <p>
            The Terms shall be governed by the laws of India, regardless of your
            actual location. You agree to the exclusive jurisdiction of Mumbai,
            Maharashtra, India.
          </p>
          <p>
            Disputes under the Terms shall be resolved through arbitration
            governed by the Arbitration and Conciliation Act, 1996 (as amended
            from time to time). The tribunal will consist of three (3)
            arbitrators to be appointed in accordance with the Arbitration and
            Conciliation Act, 1996. The seat of arbitration shall be Mumbai. The
            arbitration shall be conducted in the English language. The
            arbitrator's award shall be final, and costs for the arbitration
            shall be borne by the User.
          </p>
          <h4>20. NOTICES</h4>
          <p>
            All legal notices or demands to or upon us shall be made in writing
            and sent by courier, certified mail, or electronic email to the
            following entity and address:  1<sup>st</sup> Floor, Building - 4NA, Maker Maxity, Bandra Kurla Complex, Bandra East, Mumbai, Maharashtra - 400 051 or email at:
            customer.care@jioinsure.in. The notices shall be effective when they
            are received by us in any of the above-mentioned manner.
          </p>
          <p>
            All legal notices or demands to or upon a User shall be effective if
            either delivered personally, sent by courier, certified mail, by
            email to the last-known correspondence address or email address
            provided by the User to us, or by posting such notice or demand on
            an area of the Platform that is publicly accessible without a
            charge.{" "}
          </p>
          <p>
            Notice to a User shall be deemed to be received by such User if and
            when (a) we are able to demonstrate that communication, whether in
            physical or electronic form, has been sent to such User, or (b)
            immediately upon us posting such notice on an area of the Platform
            that is publicly accessible without charge.
          </p>
          <h4>21. ENTIRE AGREEMENT / SEVERABILITY</h4>
          <p>
            These Terms, along with the Privacy Policy, legal disclaimers, and
            other legal notices on the Platform, constitute the entire agreement
            between you and us.
          </p>
          <p>
            If any provision is deemed invalid by a court of competent
            jurisdiction, it won't affect the validity of the remaining
            provisions, which remain in full force.
          </p>
          <h4>22. COOKIES</h4>
          <p>
            We use cookies on our Platform for analysis, offering customised
            products and services, providing unique content, and enhancing user
            experience.
          </p>
          <h4>23. USAGE OF PERSONAL INFORMATION</h4>
          <p>
            Your personal information provided to us through the Platform will
            only be used and protected in accordance with our Privacy Policy.
          </p>
          <h4>24. BREACH OF THE TERMS</h4>
          <p>
            These Terms constitute a legally binding contract between you and
            JIBL. Please read them carefully and ensure you understand and agree
            to the Terms before accessing the Platform. If you have any
            questions or concerns, please contact us at
            customer.care@jioinsure.in. Without prejudice to JIBL’s other rights
            under these Terms, if you breach these Terms in any way or if JIBL
            suspects that you have breached the Terms in any way, then JIBL
            shall be entitled to take such action as it may deem necessary.
          </p>
          <h4>
            25. GRIEVANCE REDRESSAL RELATED TO POLICIES SOLICITED ON THE
            PLATFORM
          </h4>
          <ul>
            <li>
              You can register a complaint with JIBL customer's support team by
              clicking on the “Help and Support” tab in the “Profile” section on
              JioFinance or by writing an email to customer.care@jioinsure.in.
            </li>
            <li>
              All grievances shall be recorded, and due acknowledgement shall be
              issued to the User. The grievances of the Users shall be duly
              reviewed with the assistance of the respective Insurers. JIBL
              shall make every effort to address the grievance, which may
              involve promptly notifying the respective Insurer for swift
              redressal.
            </li>
            <li>
              JIBL shall take adequate steps for the redressal of grievances of
              the Policyholders within 14 days of receiving such a complaint.{" "}
            </li>
            <li>
              In the event of a grievance related to the User's information
              security, the Grievance Officer will acknowledge the complaint
              within 24 hours of receiving it and resolve it within fifteen days
              from the date of receipt. In accordance with IRDAI regulations,
              the name and contact details of the Grievance Officer are as
              follows:
            </li>
            <p>
              Name: Ms. Meenakshi Chakraborty <br />
              Email: meenaksh.c@jioinsure.in <br />
              Address: 1st Floor, B Wing, Building 25, Dhirubhai Ambani
              Knowledge City (DAKC), MIDC, Kopar Khairane, Navi Mumbai,
              Maharashtra - 400 710
              <br />
              Working hours: 09:30 Am to 06:30 Pm
            </p>
            <li>
              If the decision/resolution provided by JIBL is not acceptable,
              please use IRDAI's online portal - Integrated Grievance Management
              System (“IGMS”) or approach the Insurance Ombudsman
            </li>
            <li>
              The complaint will be marked as resolved or closed under the
              following conditions: (i) the Insurer has fully accommodated the
              complainant's request; (ii) the complainant has (g) explicitly
              accepted the Insurer's response in writing; or (iii) the
              complainant has not responded to the Insurer within 8 weeks of
              receiving the written response.
            </li>
          </ul>
          <h4>26. INSURANCE SELF NETWORK PLATFORM</h4>
          <ul>
            <li>
              Users are required to provide basic details for purchasing
              Policies from the Platform. Depending on the chosen insurance type
              / category, Users must furnish personal details such as gender,
              date of birth, smoker / nonsmoker status, income, occupation type,
              education level, vehicle details, and lump-sum coverage required
              at the claim stage.
            </li>
            <li>
              After completing the necessary information in the
              application/proposal, Users will be directed to the payment
              gateway. They can choose from payment options as provided on the
              platform and permitted by IRDAI. Upon successful payment, Users
              will receive a copy of the Policy via email to their registered
              email ID after issuance of the Policy.
            </li>
          </ul>
        </Grid>
      </Grid>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default TermsandConditions;
